#rootContainer {
}

.messageContainer {
  margin: 1rem 1rem -0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.userMessage {
  background-color: rgb(58,243,123);
  color: white;
  padding: 1rem;
  border-radius: 0.8rem;
  align-self: flex-end;
  max-width: 70%;
  word-wrap: break-word;
}

.botMessage {
  background-color: lightgrey;
  padding: 1rem;
  border-radius: 0.8rem;
  align-self: flex-start;
  max-width: 70%;
  word-wrap: break-word;
}

.choicesContainer {
  margin: 1rem 1rem -1rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  /* padding-left: 40%; */
}

.choices {
  margin: 0.1rem;
  border: 1px solid rgb(58,243,123);
  color: rgb(58,243,123);
  padding: 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
