.rootContainer {
  padding: 1rem;
  background-color: black;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

.innerContainer {
  background-color: white;
  box-sizing: border-box;
}

.chatBotContainer {
  height: 100vh;
  box-sizing: border-box;
}

.mainContainer {
  height: calc(100vh - 2rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mainContainer .title {
  font-size: 25px;
  font-family: 'News Cycle', sans-serif;
  font-weight: 700;
  white-space: nowrap;
}

.mainContainer h2 {
  font-size: 15px;
  font-family: 'Noto Serif', serif;
}

hr {
  background: #333;
  border: 0;
  height: 2px;
  margin: 12px auto 8px;
  width: 60px;
}

.mainContainer p {
  font-size: 15px;
  font-family: 'pt serif', sans-serif;
  line-height: 1.2em;
  text-align: justify;
}

.lastParagraph {
  cursor: pointer;
  font-style: italic;
}

.footerParagraph {
  font-style: italic;
}

.mainContentContainer {
  margin-top: 100px;
  width: 250px;
  text-align: center;
  box-sizing: border-box;
}

.resumeContainer {
  height: 102vh;
  box-sizing: border-box;
  text-align: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resumeContainer a, a:visited {
  text-decoration: none;
  color: white;
}

.largeImage {
  width: 40vh;
  height: 40vh;
}

.experienceTitleContainer h1 {
  margin: 0;
}

.experienceContainer {
  height: 102vh;
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  color: color;
  padding: 5%;
  box-sizing: border-box;
}

h1 {
  font-size: 50px;
  font-family: 'News Cycle', sans-serif;
}

.worksTextContainer {
  padding-top: 1%;
}

.worksTextTitle {
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.worksText {
  color: grey;
}

.companyContainer {
  /* margin-bottom: 10px; */
}

.companyImage {
  width: auto;
  height: 5vh;
}

.tapjoyImage {
  width: auto;
  height: 8vh;
}

.kakaoMobilityImage {
  width: auto;
  height: 4vh;
}
