#rootContainer {
  height: 100%;
  background-color: white;
  opacity: 0.9;
  display: flex;
  box-sizing: border-box;
}

#rightContainer {
  flex-grow: 7;
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
}

.headerContainer {
  background-color: rgb(237, 237, 237);
  height: 5%;
  display: flex;
  flex-direction: row;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  padding-left: 2%;
}

.headerNameTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2%;
  font-size: 1rem;
}

.profileImage {
  height: 100%;
}

#rightContentContainer {
  height: 90%;
  overflow-y: scroll;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#PulseLoaderContainer {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

#rightInputContainer {
  background-color: rgb(237, 237, 237);
  margin-top: auto;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237, 237, 237);
  padding-bottom: 2%;
}

.footerContainer a {
  text-decoration: none;
  font-size: 0.8rem;
}
